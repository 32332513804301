/*Plugin: $(elem).enhanceDesktopNav()*/
!function($){
	'use strict';
	var VERSION = "1.0.0";
    var EnhanceDesktopNav = EnhanceDesktopNav || {};
	EnhanceDesktopNav = (function(){
		EnhanceDesktopNav = function(element,option){
			var _ = this;
			_.stayHover;
			_.element = $(element);
			_.param = $.extend({
				mobileOff: 740,
				delay: 950,
				edgeClass: 'main-nav-edge'
			},option);

			$('li',element).has('ul').each(function(){
				!$(this).hasClass('has-children') && $(this).addClass('has-children');
			});
			$('.has-children:last',element).has('ul').addClass('last-has-children');

			_.init();
		};
		return EnhanceDesktopNav;
	})();

	EnhanceDesktopNav.prototype.init = function(){
		var _ = this;
		$(_.element).on('mouseenter mouseout','li', function(event){
			if($(window).width() > _.param.mobileOff){
				if ($('ul', this).length) {
					var elm = $('ul:first', this),
						off = elm.offset(),
						l = off.left,
						w = elm.width(),
						docW = $(window).width(),
						isEntirelyVisible = (l + w <= docW);

					(!isEntirelyVisible) && $(this).addClass(_.param.edgeClass);

					$(this).addClass('hover');

					if(event.type === 'mouseout'){
						clearTimeout(_.stayHover);
						_.stayHover = setTimeout(
							function(){
								_.element.find('li').removeClass('hover ' + _.param.edgeClass);
							},
						_.param.delay);

					}else if(event.type === 'mouseenter'){
						clearTimeout(_.stayHover);
						_.element.find('li').removeClass('hover');

					}
				}

				if(event.type === 'mouseenter'){
					clearTimeout(_.stayHover);
					_.element.find('li').removeClass('hover');
				}
			}
		});
		return _;
	};

	$.fn.enhanceDesktopNav = function() {
        var _ = this;

        for (var i = 0,l = _.length; i < l; i++) {
          _[i].enhanceDesktopNav = new EnhanceDesktopNav(_[i], arguments[0]);
        }
        return _;
    };
	$.fn.enhanceDesktopNav.version = VERSION;
}(jQuery);

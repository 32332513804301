function intelliCorpPager(elem){
	"use strict";
	var _ = this;

	_.searchLocation= (location.pathname + location.search).replace(/(\&|\?)page\=\d+/,'');
	_.page= '&page=';
	_.$pager = $(elem);
	_.currPage = Number(_.$pager.data('current-page'));
	_.totPages = Number(_.$pager.data('total-pages'));
	_.$inputPg = $('.pager-current-input',_.pager);
	_.lastOnPage = _.$pager.data('last-on-page');
	_.totalItems = _.$pager.data('total-items');
	_.pagerDelay= 300;
	_.inactiveCls= 'inactive';

	_.init();
}

(function(){
	this.prototype.init = function(elem){
		"use strict";
		var _ = this;

		_.inactiveBtnCheck();
		_.prevNextBtns();
		_.inputPageNumber();
		_.displayingResults();
	};

	this.prototype.inactiveBtnCheck = function(){
		"use strict";
		var _ = this;

		if(_.currPage === _.totPages){
			$('.page-next',_.$pager).addClass(_.inactiveCls);
		}
		if(_.currPage === 1){
			$('.page-prev',_.$pager).addClass(_.inactiveCls);
		}
	};

	this.prototype.displayingResults = function(){
		"use strict";
		var _ = this;

		$('#sr-last-on-pg').text(_.lastOnPage);
		$('#sr-items').text(_.totalItems);
	};

	this.prototype.prevNextBtns = function(){
		"use strict";
		var _ = this;

		_.$pager.on('click','.page-prev, .page-next',function(){
	 	   var isNextPage = $(this).hasClass('page-next');

	 	   if( isNextPage ){
	 		   if(_.currPage < _.totPages){
				   _.$inputPg.val(_.currPage+1);
	 			   setTimeout(function(){
	 				   window.location = _.searchLocation + _.page + (_.currPage+1);
	 			   },_.pagerDelay);
	 		   }
	 	   } else {
	 		   if(_.currPage > 1){
				   _.$inputPg.val(_.currPage-1);
	 			   setTimeout(function(){
	 				   window.location = _.searchLocation + _.page + (_.currPage-1);
	 			   },_.pagerDelay);
	 		   }
	 	   }
	    });
	};

	this.prototype.inputPageNumber = function(){
		"use strict";
		var _ = this;

		_.$pager.on('change pager.inputChanged','.pager-current-input',function(){
	 	   var theValue = this.value;

	 	   if(theValue <= _.totPages && theValue > 0){

	 		   //slight delay to see what you just did
	 		   setTimeout(function(){
	 			   window.location = _.searchLocation + _.page + theValue;
	 		   },_.pagerDelay);

	 	   }else{
	 		   console.log('page doesnt exist :-/, value: '+ theValue + '; go back to this value: ' +_.currPage);
	 		   $(this).val(_.currPage);
	 	   }
	    })
		.on('keydown','.pager-current-input',function(e){
			if (e.keyCode === 13 && this.value !== '') {
				e.preventDefault();
				e.stopPropagation();
				$(this).trigger('pager.inputChanged');
			}
		});
	};

}).call(intelliCorpPager);

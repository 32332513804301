window.HG = window.HG || {};

HG.IdGenerator = (function ($) {

  function IdGenerator(prefix) {

    var count = 0;
    prefix = String(prefix);

    this.getId = function (index) {
      if (isNaN(index)) {
        index = ++count;
      }
      return prefix + index;
    };

  }

  function create(prefix) {
    return new IdGenerator(prefix);
  }

  return {
    create: create
  };

})(jQuery);

/*!
 * HG.throttledResize
 */

jQuery(function ($) {
  'use strict';

  var settings = {
    interval: 100
  };

  var resizeTimeout = null;

  $(window).on('resize', function () {
    resizeTimeout && clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(function () {
      $(window).trigger('throttledResize');
    }, settings.interval);
  });

  window.HG = window.HG || {};
  window.HG.throttledResize = {
    settings: settings
  };
});
